import { ColDef, ColGroupDef } from "ag-grid-community";
import { GridState } from "../components/dependencies/types";
import { DependencyData } from "./types";

export interface DependencyState {
  dependencies: Array<DependencyData>;
  columnDefinitions: (ColDef | ColGroupDef)[];
  rowData?: Array<DependencyData>;
  rowCount: number;
}

export const initialModelState: DependencyState = {
  dependencies: [],
  columnDefinitions: [],
  rowCount: 0,
};

export const initialGridState: GridState = {
  sortModel: [],
  filterModel: 
  {
    status:{ filterType: "set", values: ['Failed','Notified','Waiting'] },
    // AppName:{ filterType: "text", type: "equals", filter: "PFaroeDemo" }
  },
  columnState: [
    {
      colId: "AppName",
      width: 134,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: true,
      rowGroupIndex: 0,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "dependencyName",
      width: 183,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "SchemeId",
      width: 100,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "partitionKey",
      width: 258,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "status",
      width: 109,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "started",
      width: 285,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null
    },
    {
      colId: "completed",
      width: 187,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    
    {
      colId: "Date",
      width: 128,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "ValuationDate",
      width: 159,
      hide: false,
      pinned: null,
      sort: "desc",
      sortIndex: null,
      aggFunc: null,
      rowGroup: true,
      rowGroupIndex: 2,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "DependencyType",
      width: 177,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "Client",
      width: 129,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "ClientAgent",
      width: 145,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "Location",
      width: 125,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "Type",
      width: 126,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: true,
      rowGroupIndex: 1,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "Scheme",
      width: 192,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "AssumptionBasisType",
      width: 205,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "AssumptionBasisTypeId",
      width: 125,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "LiabilityValuationHeaderId",
      width: 235,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "AllocationHeaderId",
      width: 200,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "DailyMetricId",
      width: 150,
      hide: false,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "MetricId",
      width: 150,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "PythonName",
      width: 150,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "StrategyId",
      width: 150,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "StrategyName",
      width: 150,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "id",
      width: 84,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "functionInstanceId",
      width: 187,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
    {
      colId: "operationName",
      width: 168,
      hide: true,
      pinned: null,
      sort: null,
      sortIndex: null,
      aggFunc: null,
      rowGroup: false,
      rowGroupIndex: null,
      pivot: false,
      pivotIndex: null,
      flex: null,
    },
  ],
  columnGroupState: [],
  isPivotMode: false,
};
