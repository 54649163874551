import { Loading as Spinner } from "@risk-first/ui-loading";
import React from "react";
import { LoadingWrapper } from "./Loading.style";

interface LoadingProps {
  className?: string;
}

export const Loading: React.FC<LoadingProps> = ({ className }) => (
  <LoadingWrapper className={className}>
    <Spinner />
  </LoadingWrapper>
);
