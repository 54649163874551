import { produce } from "immer";
import { types } from "./gridTypes";
import { initialGridState } from "./initialData";
import { GridState } from "../components/dependencies/types";
import { AnyAction } from "redux";

export default function gridReducer(
  state: GridState = initialGridState,
  action: AnyAction
): GridState {
  const payload = action.payload;
  switch (action.type) {
    case types.SAVE_GRID_SORT_MODEL:
      return saveGridSortModel(state, payload);

    case types.SAVE_GRID_FILTER_MODEL:
      return saveGridFilterModel(state, payload);

    case types.SAVE_GRID_COLUMN_STATE:
      return saveGridColumnState(state, payload);

    case types.SAVE_GRID_COLUMN_GROUP_STATE:
      return saveGridColumnGroupState(state, payload);

    case types.SAVE_GRID_PIVOT_MODE_STATE:
      return saveGridPivotModeState(state, payload);

    case types.SAVE_STORE_STATE_TO_LOCAL_STORAGE:
      return saveStoreStateToLocalStorage(state);
    case types.RESTORE_STORE_STATE_TO_LOCAL_STORAGE:
      return restoreStoreStateToLocalStorage();

    default:
      return state;
  }
}

function saveGridSortModel(state: GridState, { sortModel }: GridState) {
  return produce(state, (draftState) => {
    draftState.sortModel = sortModel;
  });
}

function saveGridFilterModel(state: GridState, { filterModel }: GridState) {
  return produce(state, (draftState) => {
    draftState.filterModel = filterModel;
  });
}

function saveGridColumnState(state: GridState, { columnState }: GridState) {
  return produce(state, (draftState) => {
    draftState.columnState = columnState;
  });
}

function saveGridColumnGroupState(
  state: GridState,
  { columnGroupState }: GridState
) {
  return produce(state, (draftState) => {
    draftState.columnGroupState = columnGroupState;
  });
}

function saveGridPivotModeState(state: GridState, { isPivotMode }: GridState) {
  return produce(state, (draftState) => {
    draftState.isPivotMode = isPivotMode;
  });
}

function saveStoreStateToLocalStorage(state: GridState) {
  const cache = {
    sortModel: state.sortModel,
    filterModel: state.filterModel,
    columnState: state.columnState,
    columnGroupState: state.columnGroupState,
    isPivotMode: state.isPivotMode,
  };

  const cacheStringified = JSON.stringify(cache);

  localStorage.setItem("cache", cacheStringified);

  return state;
}

function restoreStoreStateToLocalStorage() {
  return JSON.parse(localStorage.getItem("cache") ?? "");
}
