import { osirisMoodysTheme } from "@risk-first/ui-theme";
import merge from "deepmerge";
import { darken, rem } from "polished";

const breakpoints = ["48em", "60em", "80em"];

const colors = {
  border: "#e6e6eb",
  chartDashes: "#d8d8dd",
  chartTextColor: osirisMoodysTheme.colors.darkGrayishBlue,
  hyphen: "#c7c7cd",
  saffronYellow: "#f8c741",
  errorTextColor: "#e22c2c",
  moodysDarkBlueColor: "#002e5d",
  moodysBlueColor: "#0028a0",
  moodysLightBlueColor: "#009fdf",
  moodysGreyishBlueColor: "#8093cf",
  error: "#f2ccd7",
  dangerText: "#c83c58",
  success: "#dff4f0",
  successText: "#009775",
  brand: {
    primary: "#0028a0",
  },
};

const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 700,
};

const appTheme = {
  appHeader: {
    dark: {
      backgroundColor: colors.moodysDarkBlueColor,
    },
  },
  buttons: {
    icon: {
      default: {
        bg: "transparent",
      },
      osiris: {
        bg: "transparent",
      },
    },
    base: {
      default: {
        border: {
          radius: rem(5),
        },
        focus: {
          shadow: "none",
        },
        hover: {
          bg: darken(0.1, colors.moodysBlueColor),
          shadow: "none",
        },
      },
      primary: {
        border: {
          radius: rem(5),
        },
        focus: {
          shadow: "none",
        },
        hover: {
          bg: darken(0.1, colors.moodysBlueColor),
          shadow: "none",
        },
        disabled: {
          bg: colors.moodysGreyishBlueColor,
          contentColor: "white",
        },
        bg: colors.moodysBlueColor,
      },
      ghost: {
        border: {
          radius: rem(5),
        },
        focus: {
          shadow: "none",
        },
        hover: {
          shadow: "none",
        },
      },
      clear: {
        bg: "transparent",
        contentColor: osirisMoodysTheme.colors.brand.primary,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  breakpoints,
  colors,
  fontFamily: "Roboto, sans-serif;",
  fontWeight,
  mediaQueries: {
    small: `@media screen and (max-width: ${breakpoints[0]})`,
    medium: `@media screen and (max-width: ${breakpoints[1]})`,
    large: `@media screen and (max-width: ${breakpoints[2]})`,
  },
  tableDataFont: "Roboto, sans-serif;",
  tableHeaderFont: "Roboto, sans-serif;",
  space: [0, 4, 8, 16, 32, 64],
  tabMenu: {
    item: {
      default: {
        fontWeight: fontWeight.regular,
        padding: `${rem(19)} ${rem(24)} ${rem(15)}`,

        ":focus": {
          backgroundColor: osirisMoodysTheme.colors.white,
        },
      },
      secondary: {
        alignItems: "center",
        backgroundColor: osirisMoodysTheme.colors.lightGrayishBlue,
        border: `1px solid ${colors.border}`,
        borderLeft: "none",
        color: osirisMoodysTheme.colors.brand.primary,
        display: "flex",
        fontSize: rem(14),
        fontWeight: fontWeight.regular,
        height: rem(48),
        justifyContent: "center",
        minWidth: rem(170),
        padding: 0,

        ":first-of-type": {
          borderLeft: `1px solid ${colors.border}`,
        },

        ":hover": {
          backgroundColor: osirisMoodysTheme.colors.ghostWhite,
          cursor: "pointer",
        },

        '&[aria-selected="true"]': {
          backgroundColor: osirisMoodysTheme.colors.white,
          borderBottomColor: "transparent",
          fontWeight: fontWeight.medium,
        },
      },
    },
  },
  toggles: {
    icon: {
      default: {
        sizes: {
          large: {
            width: rem(35),
            height: rem(35),
          },
        },
      },
    },
    text: {
      default: {
        sizes: {
          large: {
            height: rem(35),
          },
        },
      },
    },
  },
};

export const theme = merge(osirisMoodysTheme, appTheme, {
  arrayMerge: (_, source) => source, // Overwrite instead of merging arrays
});
