import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SnackbarNotification,
} from "./snackbarActions";

import { AnyAction } from "redux";

export type SnackbarNotificationState = {
  notifications: Array<SnackbarNotification>;
};

export const initialNotificationState: SnackbarNotificationState = {
  notifications: [],
};

export const snackbarReducer = (
  state = initialNotificationState,
  action: AnyAction
): SnackbarNotificationState => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};
