import { DependencyData } from "./types";

export const getRowData = (
  dependencyData: Array<DependencyData>
): DependencyData[] => {
  const flattenedRows = dependencyData.map((item) => {
    return getFlattenedRow(item);
  });
  return flattenedRows;
};

export const getFlattenedRow = (rowData: DependencyData): DependencyData => {
  const row = Object.assign(rowData, rowData.metaData);
  return row;
};
