import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useMemo } from "react";
import { Route, RouteProps } from "react-router-dom";
import { FullHeightLoading } from "../App.style";

interface PrivateRouteProps extends RouteProps {
  path: string;
  component: React.FC;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  ...args
}) => {
  const memoizedComponent = useMemo(
    () =>
      withAuthenticationRequired(component, {
        // eslint-disable-next-line react/display-name
        onRedirecting: () => <FullHeightLoading />,
      }),
    [component]
  );

  return <Route component={memoizedComponent} {...args} />;
};
