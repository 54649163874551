import { DependencyState, initialModelState } from "./initialData";
import { ActionTypes as types } from "./actionTypes";
import { DependencyData } from "./types";
import { ColDef } from "ag-grid-community";
import { AnyAction } from "redux";
import { getFlattenedRow, getRowData } from "./rowDataUtils";

export default function modelReducer(
  state = initialModelState,
  action: AnyAction
): DependencyState {
  switch (action.type) {
    case types.RECEIVED_DEPENDENCIES_SUCCESS: {
      const distinctColumns = getDistinctColumns(action.data.rowData);
      return {
        ...state,
        dependencies: action.data,
        columnDefinitions: mergedColumns(
          distinctColumns,
          state.columnDefinitions
        ),
        rowData: getRowData(action.data.rowData),
        rowCount: action.data.rowCount,
      };
    }
    case types.RECEIVED_MESSAGE: {
      const message: DependencyData = action.data;
      let newColumns = Array<ColDef>();

      if (message.metaData != null) {
        const metaDataProperties = Object.keys(message.metaData).flat();

        newColumns = metaDataProperties
          .filter(
            (md) =>
              !state.columnDefinitions.find((cd: ColDef) => cd.field === md)
          )
          .map((columnName) => {
            return { headerName: columnName, field: columnName };
          });
      }

      const newRowData = getFlattenedRow(message);
      const updatedRowData = state.rowData?.map((rd: DependencyData) => {
        if (rd.partitionKey === newRowData.partitionKey) {
          rd.status = newRowData.status;
          rd.completed = newRowData.completed;
        }
        return rd;
      }) || [newRowData];

      return {
        ...state,
        columnDefinitions: [...state.columnDefinitions, ...newColumns],
        rowData: updatedRowData,
      };
    }
    default:
      return state;
  }
}

const mergedColumns = (
  newColumns: string[],
  existingColumns: ColDef[]
): ColDef[] => {
  const newCols = newColumns.filter(
    (c) =>
      !existingColumns
        .map((c) => c.headerName?.toLowerCase)
        .includes(c.toLowerCase)
  );
  return [...existingColumns, ...toColumnDefinitions(newCols)];
};

export const getDistinctColumns = (
  dependencyData: Array<DependencyData>
): string[] => {
  const distinctMetaDataValues = [
    ...new Set(
      dependencyData
        .map((item) => {
          let headers: string[] = [];
          if (item.metaData != null) {
            headers = Object.keys(item.metaData).flat();
          }
          return headers;
        })
        .flat()
    ),
  ];
  const columnHeaders = [
    ...new Set(
      dependencyData
        .map((d) =>
          Object.keys(d).filter(
            (x) => x !== "metaData" && x !== "dependencyName"
          )
        )
        .flat()
    ),
    ...distinctMetaDataValues,
  ];
  return columnHeaders;
};

function toColumnDefinitions(columnHeaders: string[]): ColDef[] {
  const dateTimeColumns = ["started", "completed"];
  const dateColumns = ["Date", "ValuationDate"];
  const numberColumns = ["AssumptionBasisTypeId", "LiabilityValuationHeaderId", "AllocationHeaderId", "DailyMetricId", "MetricId", "StrategyId"];
  const groupingColumns = [
    "operationName",
    "partitionKey",
    "status",
    "started",
    "completed",
    "Client",
    "ClientAgent",
    "Scheme",
    "Location",
    "ValuationDate",
    "Date",
    "LiabilityValuationHeaderId",
    "AssumptionBasisType",
    "AssumptionBasisTypeId",
    "AllocationHeaderId",
    "DailyMetricId",
    "MetricId",
    "PythonName",
    "StrategyName",
    "AppName",
    "Type"
  ];

  return [
    ...columnHeaders.map((headerName) => {
      const colDef: ColDef = {
        headerName: capitaliseFirstLetter(headerName),
        field: headerName,
      };
      if (dateColumns.includes(headerName)) {
        colDef.type = "dateColumn";
      } else if (dateTimeColumns.includes(headerName)) {
        colDef.type = "dateTimeColumn";
      } else if (headerName === "status") {
        colDef.type = "statusColumn";
      } else if (numberColumns.includes(headerName)) {
        colDef.type = "numberColumn";
      } else {
        colDef.type = "textColumn";
      }

      if (groupingColumns.includes(headerName)) {
        colDef.type = [colDef.type, "groupColumn"];
      }

      return colDef;
    }),
  ];
}

function capitaliseFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
