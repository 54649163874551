import styled from "@emotion/styled";
import { H2 } from "@risk-first/ui-core";
import { rem } from "polished";

export const HomePage = styled.div`
  padding: ${rem(5)};
`;

export const PageHeading = styled(H2)`
    margin-bottom: ${rem(5)};
    line-height: 1.5;
`;