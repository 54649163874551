export interface DependencyData {
  id: string;
  functionInstanceId: string;
  operationName: string;
  partitionKey: string;
  dependencyName: string;
  metaData: { [key: string]: unknown };
  metaDataAsString: string;
  status: DependencyStatus;
  started: Date;
  completed: Date;
}

export enum DependencyStatus {
  Waiting = 0,
  Completed = 1,
  Failed = 2,
  Notified = 3,
  Terminated = 4,
  Abandoned = 5
}

export class DependencyCheckClient{
  dependencyKey: string;
  client: unknown;

  constructor(partitionKey:string, client:unknown){
    this.dependencyKey = partitionKey;
    this.client = client;
  }
}

export class DependencyCheckClientAgent{
  dependencyKey: string;
  clientAgent: unknown;

  constructor(partitionKey:string, clientAgent:unknown){
    this.dependencyKey = partitionKey;
    this.clientAgent = clientAgent;
  }
}

export class RestorePendingDependencies{
  appName: unknown;
  clientAgent: unknown;
  client: unknown;
  schemeId: unknown;
  type: unknown;
  valuationDate: unknown;

  constructor(
    appName: unknown,  
    clientAgent: unknown,
    client: unknown,
    schemeId: unknown,
    type: unknown,
    valuationDate: unknown)
    {
      this.appName = appName;
      this.clientAgent = clientAgent;
      this.client = client;
      this.schemeId = schemeId;
      this.type = type;
      this.valuationDate = valuationDate;
    }
}