import { useAuth0 } from "@auth0/auth0-react";
import fetchIntercept from "fetch-intercept";
import React, { useLayoutEffect, useEffect } from "react";
import getHistory from "../lib/history";

interface Props {
  children?: (token: string) => React.ReactNode;
}
export const AccessToken: React.FC<Props> = ({ children }) => {
  const [token, setToken] = React.useState("");
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useLayoutEffect(() => {
    const unregister = fetchIntercept.register({
      request: async (url, config) => {
        const accessToken = await getAccessTokenSilently();

        if (!isAuthenticated) {
          redirectToLogout();
        }

        if (accessToken) {
          setToken(accessToken);
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        return [url, config];
      },
    });

    return () => {
      unregister();
    };
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        setToken(await getAccessTokenSilently());
      })();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const redirectToLogout = () => {
    getHistory().push({
      pathname: "/login",
      search: getHistory().location.search,
      state: {
        from: getHistory().location,
        message:
          "Your session has expired, please login again to access your data.",
      },
    });
  };

  return <>{children ? children(token) : null}</>;
};
