import { useAuth0 } from "@auth0/auth0-react";
import { Login as LoginComponent } from "@risk-first/ui-login";
import React, { useCallback, useEffect } from "react";
import * as H from "history";

interface LoginProps {
  history: H.History;
}

export const Login: React.FC<LoginProps> = ({ history }) => {
  const logo = React.useMemo(() => <h1>Cozonac</h1>, []);

  useEffect(() => {
    document.title = "Login";
  }, []);

  const { loginWithRedirect } = useAuth0();

  const handleClick = useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      history.replace("/");
    }
  }, [isAuthenticated, history]);

  return (
    <>
      {!isAuthenticated && (
        <LoginComponent
          applicationLogo={logo}
          applicationName="Cozonac"
          onLoginClick={handleClick}
        />
      )}
    </>
  );
};
