import { Auth0ContextInterface } from "@auth0/auth0-react";
import { IServerSideGetRowsParams } from "ag-grid-community";
import axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import setupSignalRConnection from "../lib/signalRHubConnection";
import { ActionTypes as types } from "./actionTypes";
import {
  DependencyCheckClientAgent,
  DependencyCheckClient,
  DependencyData,
  RestorePendingDependencies,
} from "./types";
import { getRowData } from "./rowDataUtils";
import { RootState } from ".";
import { enqueueSnackbar as enqueueSnackbarAction } from "../store/snackbarActions";

const cozonacApiUrl = process.env.REACT_APP_COZONAC_API_URL;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const actions = {
  fetchDependencies(auth0Context: Auth0ContextInterface): AppThunk {
    return async (dispatch) => {
      dispatch({ type: types.REQUEST_DEPENDENCIES, data: auth0Context });
      const { getAccessTokenSilently } = auth0Context;
      try {
        const token = await getAccessTokenSilently();

        const resp = await axios.get(`${cozonacApiUrl}/api/dependencies`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        });

        dispatch({
          type: types.RECEIVED_DEPENDENCIES_SUCCESS,
          data: resp.data,
        });
      } catch (err) {
        dispatch({
          type: types.RECEIVED_DEPENDENCIES_ERROR,
          error: getErrorString(err),
        });
      }
    };
  },

  fetchDependenciesDatasource(
    auth0Context: Auth0ContextInterface,
    params: IServerSideGetRowsParams
  ): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: types.REQUEST_DEPENDENCIES,
        data: params.request,
        error: null,
      });
      const { getAccessTokenSilently } = auth0Context;
      try {
        const token = await getAccessTokenSilently();

        const resp = await axios.post(
          `${cozonacApiUrl}/api/dependencies/dataSource`,
          params.request,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );

        dispatch({
          type: types.RECEIVED_DEPENDENCIES_SUCCESS,
          data: resp.data,
          error: null,
        });

        params.success({
          rowData: getRowData(resp.data.rowData),
          rowCount: resp.data.rowCount,
        });
      } catch (err) {
        dispatch({
          type: types.RECEIVED_DEPENDENCIES_ERROR,
          error: getErrorString(err),
        });
        params.fail();
      }
    };
  },

  setupSignalRHub(auth0Context: Auth0ContextInterface): AppThunk {
    return async (dispatch) => {
      const { getAccessTokenSilently } = auth0Context;

      try {
        const token = await getAccessTokenSilently();
        dispatch({ type: types.REQUEST_CONNECTION_INFO, data: token });
        const resp = await axios.get(
          `${cozonacApiUrl}/api/hub/negotiate/dependencies`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: types.RECEIVED_CONNECTION_INFO_SUCCESS,
          data: resp.data,
        });

        dispatch({ type: types.REQUEST_HUB_CONNECTION, data: resp.data });

        const setupEventsHub = await setupSignalRConnection(
          resp.data.url,
          {
            onMessageEvent: (message: DependencyData) => {
              const action = { type: types.RECEIVED_MESSAGE, data: message };
              dispatch(action);
              return action;
            },
          },
          resp.data.accessToken
        );
        dispatch(setupEventsHub);
      } catch (err) {
        dispatch({
          type: types.RECEIVED_CONNECTION_INFO_ERROR,
          error: getErrorString(err),
        });
      }
    };
  },

  checkDependencyExists(
    auth0Context: Auth0ContextInterface,
    payload: Array<string>
  ): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: types.REQUEST_CHECK_DEPENDENCY,
        data: payload,
        error: null,
      });
      const { getAccessTokenSilently } = auth0Context;

      try {
        const token = await getAccessTokenSilently();

        const resp = await axios.post(
          `${cozonacApiUrl}/api/dependencies/status`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: types.RECEIVED_CHECK_DEPENDENCY_SUCCESS,
          data: resp.data,
          error: null,
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Successfully sent message to check dependencies are ready to be processed`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "success",
            },
          })
        );
      } catch (err) {
        dispatch({
          type: types.RECEIVED_CHECK_DEPENDENCY_ERROR,
          data: getErrorString(err),
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Error checking dependencies`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "error",
            },
          })
        );
      }
    };
  },

  abandonDependencies(
    auth0Context: Auth0ContextInterface,
    payload: Array<DependencyData>
  ): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: types.REQUEST_ABANDON_DEPENDENCY,
        data: payload,
        error: null,
      });
      const { getAccessTokenSilently } = auth0Context;

      try {
        const token = await getAccessTokenSilently();

        const resp = await axios.post(
          `${cozonacApiUrl}/api/dependencies/abandon`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: types.RECEIVED_ABANDON_DEPENDENCY_SUCCESS,
          data: resp.data,
          error: null,
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Successfully abandoned dependencies`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "success",
            },
          })
        );
      } catch (err) {
        dispatch({
          type: types.RECEIVED_ABANDON_DEPENDENCY_ERROR,
          error: getErrorString(err),
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Abandon dependencies failed`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "error",
            },
          })
        );
      }
    };
  },

  forceClientDependency(
    auth0Context: Auth0ContextInterface,
    payload: Array<DependencyCheckClient>
  ): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: types.REQUEST_CHECK_DEPENDENCY,
        data: payload,
        error: null,
      });
      const { getAccessTokenSilently } = auth0Context;

      try {
        const token = await getAccessTokenSilently();

        const resp = await axios.post(
          `${cozonacApiUrl}/api/dependencies/skipClientDependencyCheck`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: types.RECEIVED_CHECK_DEPENDENCY_SUCCESS,
          data: resp.data,
          error: null,
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Successfully sent message to skip dependency check for selected clients`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "success",
            },
          })
        );
      } catch (err) {
        dispatch({
          type: types.RECEIVED_CHECK_DEPENDENCY_ERROR,
          data: getErrorString(err),
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Error skipping client dependencies`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "error",
            },
          })
        );
      }
    };
  },

  forceClientAgentDependency(
    auth0Context: Auth0ContextInterface,
    payload: Array<DependencyCheckClientAgent>
  ): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: types.REQUEST_CHECK_DEPENDENCY,
        data: payload,
        error: null,
      });
      const { getAccessTokenSilently } = auth0Context;

      try {
        const token = await getAccessTokenSilently();

        const resp = await axios.post(
          `${cozonacApiUrl}/api/dependencies/skipClientAgentDependencyCheck`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: types.RECEIVED_CHECK_DEPENDENCY_SUCCESS,
          data: resp.data,
          error: null,
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Successfully sent message to skip dependency check for selected clients`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "success",
            },
          })
        );
      } catch (err) {
        dispatch({
          type: types.RECEIVED_CHECK_DEPENDENCY_ERROR,
          data: getErrorString(err),
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Error skipping client dependencies`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "error",
            },
          })
        );
      }
    };
  },

  restorePendingDependencies(
    auth0Context: Auth0ContextInterface,
    payload: Array<RestorePendingDependencies>
  ): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: types.RESTORE_PENDING_DEPENDENCY,
        data: payload,
        error: null,
      });
      const { getAccessTokenSilently } = auth0Context;

      try {
        const token = await getAccessTokenSilently();

        const resp = await axios.post(
          `${cozonacApiUrl}/api/dependencies/restorePendingDependencies`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: types.RESTORE_PENDING_DEPENDENCY_SUCCESS,
          data: resp.data,
          error: null,
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Successfully sent restore pending dependencies command`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "success",
            },
          })
        );
      } catch (err) {
        dispatch({
          type: types.RESTORE_PENDING_DEPENDENCY_ERROR,
          data: getErrorString(err),
        });
        dispatch(
          enqueueSnackbarAction({
            message: `Error sending restore pending dependencies command`,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "error",
            },
          })
        );
      }
    };
  },
};

const getErrorString = (err: unknown): string => {
  if (err instanceof Error) {
    return err.message;
  } else if (typeof err === "string") {
    return err;
  } else {
    return "Unknown error";
  }
};
