import {
  JsonHubProtocol,
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
  HubConnection,
} from "@microsoft/signalr";
import { ThunkDispatch } from "redux-thunk";
import { DependencyState } from "../store/initialData";
import { SnackbarNotificationState } from "../store/snackbackReducer";
import { Action } from "redux";
import { ErrorState } from "../store/errorReducer";
import { GridState } from "../components/dependencies/types";
import { DependencyData } from "../store/types";

const isDev = process.env.NODE_ENV === "development";

const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
    console.assert(connection.state === HubConnectionState.Connected);
    console.log("SignalR connection established");
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.error("SignalR Connection Error: ", err);
    setTimeout(() => startSignalRConnection(connection), 5000);
  }
};

type Dispatch = ThunkDispatch<
  {
    grid: GridState;
    model: DependencyState;
    error: ErrorState;
    snackbar: SnackbarNotificationState;
  },
  unknown,
  Action<string>
>;

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRConnection =
  (
    connectionHubUrl: string,
    actionEventMap: {
      [eventName: string]: (message: DependencyData) => Action<string>;
    } = {},
    accessToken: string
  ) =>
  (dispatch: Dispatch): HubConnection => {
    const options = {
      logMessageContent: isDev,
      logger: isDev ? LogLevel.Warning : LogLevel.Error,
      //accessTokenFactory: () => accessToken,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    // create the connection instance
    // withAutomaticReconnect will automatically try to reconnect
    // and generate a new socket connection if needed
    const connection = new HubConnectionBuilder()
      .withUrl(connectionHubUrl, options)
      .withAutomaticReconnect()
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Information)
      .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    connection.serverTimeoutInMilliseconds = 60000;

    // re-establish the connection if connection dropped
    connection.onclose((error) => {
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.log(
        "Connection closed due to error. Try refreshing this page to restart the connection",
        error
      );
    });

    connection.onreconnecting((error) => {
      console.assert(connection.state === HubConnectionState.Reconnecting);
      console.log("Connection lost due to error. Reconnecting.", error);
    });

    connection.onreconnected((connectionId) => {
      console.assert(connection.state === HubConnectionState.Connected);
      console.log(
        "Connection reestablished. Connected with connectionId",
        connectionId
      );
    });

    startSignalRConnection(connection);

    connection.on("SendMessageToClient", (res) => {
      const eventHandler = actionEventMap["onMessageEvent"];
      eventHandler && dispatch(eventHandler(res));
    });

    return connection;
  };

export default setupSignalRConnection;
