import { ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { DependencyStatus } from "../../store/types";

export const formatDate = (data: ValueFormatterParams): string => {
  const momentDate = moment(data.value);
  if (momentDate.isSame("0001-01-01")) {
    return "";
  }
  return momentDate.format("DD/MM/YYYY");
};

export const formatDateTime = (data: ValueFormatterParams): string => {
  const momentDate = moment(data.value);
  if (momentDate.isSame("0001-01-01T00:00:00")) {
    return "";
  }
  return momentDate.format("DD/MM/YYYY HH:mm:ss");
};

export const formatStatus = (params: ValueFormatterParams): string => {
  return DependencyStatus[params.value];
};

export const dateComparator = (
  filterLocalDateAtMidnight: Date,
  cellValue: Date
): number => {
  const momentDate = moment(cellValue);
  if (momentDate.isSame(filterLocalDateAtMidnight, "day")) {
    return 0;
  } else if (momentDate.isAfter(filterLocalDateAtMidnight, "day")) {
    return 1;
  } else return -1;
};
