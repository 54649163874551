import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0ProviderWithHistory } from "./auth/Auth0ProviderWithHistory";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import store from "./store";

const baseUrl =
  document.getElementsByTagName("base")[0].getAttribute("href") || "";
const rootElement = document.getElementById("root");

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={store}>
    <Router basename={baseUrl}>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </Provider>,
  rootElement
);

registerServiceWorker();
