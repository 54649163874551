import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import { AppFooter } from "@risk-first/ui-app-footer";

export const Wrapper = styled.div`
  background-color: ${themeGet("colors.lightGrayishBlue")};
  color: ${themeGet("colors.light")};
  font-family: ${themeGet("fontFamily")};
  min-height: 100vh;
`;

export const CozonacFooter = styled(AppFooter)`
  min-height: fit-content;
`;
