import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import React from "react";
import { useHistory } from "react-router-dom";

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = React.useCallback<
    (appState?: AppState, user?: User) => void
  >(
    (appState: AppState | undefined) => {
      history.push(appState?.returnTo || window.location.pathname);
    },
    [history]
  );

  return (
    <Auth0Provider
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      cacheLocation="localstorage"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
