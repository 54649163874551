import { createLogger } from "redux-logger";
import modelReducer from "./modelReducer";
import gridReducer from "./gridReducer";
import { errorReducer, initialErrorState } from "./errorReducer";
import { snackbarReducer, initialNotificationState } from "./snackbackReducer";
import { initialGridState, initialModelState } from "./initialData";
import { configureStore } from "@reduxjs/toolkit";
import { getFilterDates } from "./../components/dependencies/gridUtils";

const logger = createLogger({
  collapsed: true,
});

const initialState = {
  grid: initialGridState,
  model: initialModelState,
  error: initialErrorState,
  snackbar: initialNotificationState,
};
const cache = window.localStorage.getItem("cache");

if (cache) {
  const cacheParsed = JSON.parse(cache);
  initialState.grid = { ...cacheParsed };
}

initialState.grid.filterModel["ValuationDate"] = {dateFrom: getFilterDates().from,  dateTo: getFilterDates().to,  filterType: "date",  type: "inRange"};

const store = configureStore({
  reducer: {
    grid: gridReducer,
    model: modelReducer,
    error: errorReducer,
    snackbar: snackbarReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState: initialState,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
