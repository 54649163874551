export const types = {
  // COMPOSITE ACTIONS
  CREATE_NEW_VIEW: "CREATE_NEW_VIEW",
  DESTROY_VIEW: "DESTROY_VIEW",

  CREATE_NEW_TAB: "CREATE_NEW_TAB",
  DESTROY_TAB: "DESTROY_TAB",

  // ELEMENTARY ACTIONS
  ADD_NEW_VIEW: "ADD_NEW_VIEW",
  REMOVE_VIEW: "REMOVE_VIEW",
  CHANGE_VIEW_NAME: "CHANGE_VIEW_NAME",

  CHANGE_VIEW: "CHANGE_VIEW",
  ADD_NEW_TAB: "ADD_NEW_TAB",
  CHANGE_TAB_NAME: "CHANGE_TAB_NAME",

  SET_TAB_INDEX: "SET_TAB_INDEX",
  SET_CURRENT_VIEW_INFO_TO_NULL: "SET_CURRENT_VIEW_INFO_TO_NULL",
  SET_GRID_DATA: "SET_GRID_DATA",
  FETCH_GRID_DATA: "FETCH_GRID_DATA",

  SAVE_GRID_SORT_MODEL: "SAVE_GRID_SORT_MODEL",
  SAVE_GRID_FILTER_MODEL: "SAVE_GRID_FILTER_MODEL",

  SAVE_GRID_COLUMN_STATE: "SAVE_GRID_COLUMN_STATE",
  SAVE_GRID_COLUMN_GROUP_STATE: "SAVE_GRID_COLUMN_GROUP_STATE",
  SAVE_GRID_PIVOT_MODE_STATE: "SAVE_GRID_PIVOT_MODE_STATE",

  SAVE_STORE_STATE_TO_LOCAL_STORAGE: "SAVE_STORE_STATE_TO_LOCAL_STORAGE",
  RESTORE_STORE_STATE_TO_LOCAL_STORAGE: "RESTORE_STORE_STATE_TO_LOCAL_STORAGE",

  SET_GRID_FETCH_AGAIN_TO_FALSE: "SET_GRID_FETCH_AGAIN_TO_FALSE",
};
