/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { ThemeProvider } from "@emotion/react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { PrivateRoute } from "./auth/PrivateRoute";
import { Home, Login, Callback } from "./pages";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import { FullHeightLoading } from "./App.style";

const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      // @ts-ignore
      <ThemeProvider theme={theme}>
        <FullHeightLoading />
      </ThemeProvider>
    );
  }
  return (
    // @ts-ignore
    <ThemeProvider theme={theme}>
      {/* @ts-ignore */}
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <BrowserRouter>
          <Switch>
            <Route component={Login} path="/login" />
            <Route
              path="/callback"
              render={(props) => <Callback {...props} />}
            />
            <PrivateRoute exact component={Home} path="/" />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
