import styled from "@emotion/styled";
import { Button } from "@risk-first/ui-core";
import { rem } from "polished";

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GridButton = styled(Button)`
  min-width: ${rem(100)};
`;