import { AnyAction } from "redux";
import { GridState } from "../components/dependencies/types";
import { types } from "./gridTypes";

export const gridActions = {
  saveGridSortModel(sortModel: GridState["sortModel"]): AnyAction {
    return {
      type: types.SAVE_GRID_SORT_MODEL,
      payload: { sortModel },
    };
  },
  saveGridFilterModel(filterModel: GridState["filterModel"]): AnyAction {
    return {
      type: types.SAVE_GRID_FILTER_MODEL,
      payload: { filterModel },
    };
  },

  saveGridColumnState(columnState: GridState["columnState"]): AnyAction {
    return {
      type: types.SAVE_GRID_COLUMN_STATE,
      payload: { columnState },
    };
  },

  saveGridColumnGroupState(columnGroupState: GridState["columnGroupState"]): AnyAction {
    return {
      type: types.SAVE_GRID_COLUMN_GROUP_STATE,
      payload: { columnGroupState },
    };
  },

  saveGridPivotModeState(isPivotMode: GridState["isPivotMode"]): AnyAction {
    return {
      type: types.SAVE_GRID_PIVOT_MODE_STATE,
      payload: { isPivotMode },
    };
  },

  // except for rowData
  saveStoreStateToLocalStorage(): AnyAction {
    return {
      type: types.SAVE_STORE_STATE_TO_LOCAL_STORAGE,
    };
  },

  restoreStoreStateToLocalStorage(): AnyAction {
    return {
      type: types.RESTORE_STORE_STATE_TO_LOCAL_STORAGE,
    };
  },
};
