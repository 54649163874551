import keyMirror from "keymirror";

export const ActionTypes = keyMirror({
  REQUEST_DEPENDENCIES: null,
  RECEIVED_DEPENDENCIES_SUCCESS: null,
  RECEIVED_DEPENDENCIES_ERROR: null,
  REQUEST_CONNECTION_INFO: null,
  RECEIVED_CONNECTION_INFO_SUCCESS: null,
  RECEIVED_CONNECTION_INFO_ERROR: null,
  REQUEST_CHECK_DEPENDENCY: null,
  REQUEST_DELETE_DEPENDENCY: null,
  REQUEST_ABANDON_DEPENDENCY: null,
  RECEIVED_CHECK_DEPENDENCY_SUCCESS: null,
  RECEIVED_CHECK_DEPENDENCY_ERROR: null,
  RECEIVED_ABANDON_DEPENDENCY_SUCCESS: null,
  RECEIVED_ABANDON_DEPENDENCY_ERROR: null,
  RECEIVED_DELETE_DEPENDENCY_SUCCESS: null,
  RECEIVED_DELETE_DEPENDENCY_ERROR: null,
  REQUEST_HUB_CONNECTION: null,
  RECEIVED_HUB_CONNECTION_SUCCESS: null,
  RECEIVED_HUB_CONNECTION_ERROR: null,
  RECEIVED_MESSAGE: null,
  RESTORE_PENDING_DEPENDENCY: null,
  RESTORE_PENDING_DEPENDENCY_SUCCESS: null,
  RESTORE_PENDING_DEPENDENCY_ERROR: null,
});
