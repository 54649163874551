import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { AnyAction } from "redux";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export type SnackbarNotification = {
  key: SnackbarKey;
  message: SnackbarMessage;
  options?: OptionsObject;
  dismissed?: boolean;
};

export const enqueueSnackbar = (
  notification: SnackbarNotification
): AnyAction => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key: SnackbarKey): AnyAction => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key: SnackbarKey): AnyAction => ({
  type: REMOVE_SNACKBAR,
  key,
});
