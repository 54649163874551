import React from "react";
import { DependenciesGrid } from "../components/dependencies";
import { Layout } from "../components";
import { HomePage, PageHeading } from "./Home.style";

export const Home: React.FC = () => {
  return (
    <Layout>
      <HomePage>
        <PageHeading>PFaroe Daily Dependencies</PageHeading>
        <DependenciesGrid />
      </HomePage>
    </Layout>
  );
};
