import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { FullHeightLoading } from "../App.style";
import * as H from "history";

interface CallbackProps {
  history: H.History;
}

export const Callback: React.FC<CallbackProps> = ({ history }) => {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      history.replace("/");
    } else {
      history.replace("/login");
    }
  }, [isAuthenticated, history]);

  return <FullHeightLoading />;
};
