import { useAuth0 } from "@auth0/auth0-react";
import { LogoutOptions } from "@auth0/auth0-spa-js";
import { AppHeader } from "@risk-first/ui-app-header";
import { withTheme } from "@emotion/react";
import React, { useCallback } from "react";
import { AccessToken } from "../auth/AccessToken";
import { Notifier } from "./Notifier";
import { CozonacFooter, Wrapper } from "./Layout.style";

export const Layout: React.FC = withTheme((props) => {
  const { isAuthenticated, user, logout } = useAuth0();
  const logo = React.useMemo(() => <span>Cozonac</span>, []);

  // Ensure Auth0 logout goes to our login page.
  // Make sure the URL is set in the Auth0 management console's 'Allowed Logout URLs' list
  const handleLogout = useCallback(() => {
    const returnTo = `${window.location.origin}/login`;
    const logoutOptions: LogoutOptions = {
      returnTo,
    };
    logout(logoutOptions);
  }, [logout]);

  return (
    <Wrapper>
      {isAuthenticated && (
        <AppHeader
          hideMenuButton={true}
          logo={logo}
          user={{ name: user?.name, picture: user?.picture }}
          variant="dark"
          onLogout={handleLogout}
        />
      )}
      <Notifier />
      <main id="content">{props.children}</main>
      <AccessToken />
      <CozonacFooter
        brandName="Moody's Analytics"
        contentWidth=""
        withSecondaryLogo
      ></CozonacFooter>
    </Wrapper>
  );
});
