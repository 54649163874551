import { GridApi, GridReadyEvent } from "ag-grid-community";
import moment from "moment";

//work around for known issue with ag-grid when using server-side data
//https://github.com/ag-grid/ag-grid/issues/2662
export const onCacheBlockLoaded = (
  gridParams: GridReadyEvent,
  cb: (params: GridReadyEvent) => void
): void => {
  const interval = setInterval(() => {
    if (checkIfBlocksLoaded(gridParams.api)) {
      clearInterval(interval);
      cb(gridParams);
    }
  }, 500);

  function checkIfBlocksLoaded(api: GridApi) {
    if (api.getCacheBlockState() === null) return false;

    const status = api.getCacheBlockState()[0]
      ? api.getCacheBlockState()[0].pageStatus
      : false;

    return status === "loaded";
  }
};

export const getLastWeekDate = () =>
{
  const currentDate = new Date();
  const date = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
  date.setDate(date.getDate() - 7);
  return date;
};

export const getLast10YearDate = () =>
{
  const currentDate = new Date();
  const date = new Date(currentDate.getUTCFullYear()-10, currentDate.getUTCMonth(), currentDate.getUTCDate());
  return date;
};

export const getFilterDates = () =>
{
  const currentDate = new Date();
  const date = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
  
  const fromDate = moment(getLastWeekDate()); 
  const toDate = moment(date);

  const fromDateString = fromDate.format("YYYY-MM-DD HH:mm:ss");
  const toDateString = toDate.format("YYYY-MM-DD HH:mm:ss");

  return {from: fromDateString, to: toDateString};
};