import { AnyAction } from "redux";
import { HIDE_ERROR } from "./errorTypes";

export type ErrorState = {
  error: string | null;
  isVisible: boolean;
};

export const initialErrorState: ErrorState = {
  error: null,
  isVisible: false,
};

export function errorReducer(
  state = initialErrorState,
  action: AnyAction
): ErrorState {
  const { error } = action;

  if (error) {
    return {
      error: error,
      isVisible: true,
    };
  } else if (action.type === HIDE_ERROR) {
    return {
      error: null,
      isVisible: false,
    };
  }

  return state;
}
